import React from "react";
import { useMediaQuery } from "@geist-ui/react";

import { NameSec, SocialIcons, TextSec } from "./LeftPaneComponents";
import DarkModeButton from "./darkModeButton";

const LeftPane = () => {
  const isXs = useMediaQuery("xs");

  return (
    <div className={!!isXs ? undefined : "right-pane"}>
      {isXs ? <DarkModeButton /> : undefined}

      {/* Avatar and name */}
      <NameSec />

      {/* Social Icons */}
      <SocialIcons />

      {/* All texts go here */}
      <TextSec />
    </div>
  );
};

export default LeftPane;
