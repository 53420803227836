import { Text, Grid, Image } from "@geist-ui/react";
import React, { useRef, useEffect, useState } from "react";

const NameSec = () => {
  const textAreaRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 1, height: 1 });

  const getTextAreaSize = () => {
    if (textAreaRef.current != null) {
      const newWidth = textAreaRef.current.clientWidth;
      const newHeight = textAreaRef.current.clientHeight;
      setDimensions({ width: newWidth, height: newHeight });
    }
  };

  // listen on resize
  useEffect(() => {
    window.addEventListener("resize", getTextAreaSize);
  }, [textAreaRef]);

  // read on mount
  useEffect(() => {
    getTextAreaSize();
  }, []);

  return (
    <Grid.Container gap={1} justify="center">
      <Grid xs={12}>
        <div className="left-pane-avatar">
          <Image
            style={{ borderRadius: "30%" }}
            src={process.env.PUBLIC_URL + "/avatar_img.webp"}
          />
        </div>
      </Grid>
      <Grid xs={12}>
        <div className="left-pane-name-area" ref={textAreaRef}>
          <Text
            h1
            className="left-pane-nick-name"
            font={dimensions.width * 0.02}
          >
            Zack
          </Text>
          <Text
            h1
            className="left-pane-real-name"
            font={dimensions.width * 0.01}
          >
            Weihao Zeng
          </Text>
        </div>
      </Grid>
    </Grid.Container>
  );
};

export default NameSec;
