import React from "react";
import { useTheme } from "@geist-ui/react";

const Image = React.lazy(() =>
  import("@geist-ui/react").then((module) => ({ default: module.Image }))
);

const LazyColorImg = ({
  src = undefined,
  lightImg = undefined,
  darkImg = undefined,
  scale = 1,
}) => {
  const theme = useTheme();
  const ifDark = theme.type.toLowerCase().includes("dark");

  lightImg = !!!lightImg ? src : lightImg;
  darkImg = !!!darkImg ? lightImg : darkImg;

  const imgPath = ifDark ? darkImg : lightImg;

  return (
    <Image
      width={`${scale * 100}%`}
      style={{margin: 'auto'}}
      src={process.env.PUBLIC_URL + imgPath}
    />
  );
};

export default LazyColorImg;
