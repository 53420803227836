import React, { useContext } from "react";
import { Button, Text } from "@geist-ui/react";
import { Moon, Sun } from "@geist-ui/react-icons";

import ThemeContext from "./themeContext";

const DarkModeButton = () => {
  const theme = useContext(ThemeContext);
  const isDark = theme.isDark;

  const toggleTheme = () => theme.setIsDark(!theme.isDark);

  const icon = isDark ? <Moon /> : <Sun />;

  return (
    <div className="dark-mode-button-container">
      <Button auto icon={icon} onClick={toggleTheme}>
        <Text b>{isDark ? "Dark" : "Light"}</Text>
      </Button>
    </div>
  );
};

export default DarkModeButton;
