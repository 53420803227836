import { Text, Grid, useTheme, Spacer, Image } from "@geist-ui/react";
import React, { useRef, useEffect, useState, useCallback } from "react";

const TextSec = () => {
  const theme = useTheme();

  const textAreaRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 1, height: 1 });
  const [ifSmall, setIfSmall] = useState(true);

  const getArea = useCallback(() => {
    setDimensions({
      width: textAreaRef.current.clientWidth,
      height: textAreaRef.current.clientHeight,
    });

    const windowWidth = window.innerWidth;
    const xsBreakPix = parseInt(theme.breakpoints.xs.max, 10);

    setIfSmall(windowWidth > xsBreakPix);
  }, [theme.breakpoints.xs.max]); // Include 'theme.breakpoints.xs.max' as a dependency


  // listen on resize
  useEffect(() => {
    window.addEventListener("resize", getArea);
  }, [textAreaRef, getArea]);

  // read on mount
  useEffect(() => {
    getArea();
  }, [getArea]);

  const tags = [
    ["Self-driving", true],
    ["Robotics", true],
    ["Software developer", false],
    ["Deep Reinforcement Learning", true],
    ["Explainable AI", true],
    ["Act", false],
    ["Computer Vision", true],
    ["Leadership", true],
    ["Minimalist", false],
    ["Doge advocate", true],
  ];

  const shortBio =
    "Let's make intelligent robots ubiquitous! I love doge(s).";

  return (
    <div className="left-pand-tex-sec" ref={textAreaRef}>
      {/* ShortBio */}
      <Text font={"1.2rem"}>{shortBio}</Text>

      {/* Tags */}
      <Text>
        {tags.map(([tag, important], i) => (
          <Text
            key={i}
            span
            // font={important ? "2rem" : "1rem"}
            {...(important ? { font: "1.4rem", b: true } : { font: "1.2rem" })}
          >
            {tag}.{" "}
          </Text>
        ))}
      </Text>

      <Spacer />

      {/* Education */}

      <Grid.Container gap={2} justify="flex-start">
        <Grid xs={8} md={8}>
          <Image
            width={"100%"}
            style={{ opacity: 0.95 }}
            src={process.env.PUBLIC_URL + "/cmu-seal-r.svg"}
          />
        </Grid>
        <Grid xs={12} md={12} className="left-pane-school-exp">
          {ifSmall ? (
            <div>
              <Text p b mb={0} mt={0} font={dimensions.width * 0.003}>
                Carnegie Mellon University
              </Text>
              <Text mb={0} mt={0} font={dimensions.width * 0.002}>
                Aug. 2022 - May. 2024
              </Text>
              <Text mb={0} mt={0} font={dimensions.width * 0.002}>
                M.S. Robotics
              </Text>
            </div>
          ) : (
            <div>
              <Text p b mb={0} mt={0} font={dimensions.width * 0.004}>
                CMU
              </Text>
              <Text mb={0} mt={0} font={dimensions.width * 0.0025}>
                Aug. 2022 - May. 2024
              </Text>
              <Text mb={0} mt={0} font={dimensions.width * 0.0025}>
                M.S. Robotics
              </Text>
            </div>
          )}
        </Grid>
      </Grid.Container>

      <Grid.Container gap={2} justify="flex-start">
        <Grid xs={8} md={8}>
          <Image
            width={"100%"}
            src={process.env.PUBLIC_URL + "/ucsd-logo.svg"}
          />
        </Grid>
        <Grid xs={12} md={12} className="left-pane-school-exp">
          {ifSmall ? (
            <div>
              <Text p b mb={0} mt={0} font={dimensions.width * 0.003}>
                University of California San Diego
              </Text>
              <Text mb={0} mt={0} font={dimensions.width * 0.002}>
                Sep. 2017 - Dec. 2020
              </Text>
              <Text mb={0} mt={0} font={dimensions.width * 0.002}>
                B.S. in Computer Science
              </Text>
            </div>
          ) : (
            <div>
              <Text p b mb={0} mt={0} font={dimensions.width * 0.004}>
                UC San Diego
              </Text>
              <Text mb={0} mt={0} font={dimensions.width * 0.0025}>
                Sep. 2017 - Dec. 2020
              </Text>
              <Text mb={0} mt={0} font={dimensions.width * 0.0025}>
                B.S. Comp. Sci.
              </Text>
            </div>
          )}
        </Grid>
      </Grid.Container>
    </div>
  );
};

export default TextSec;
