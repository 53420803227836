import React, { useRef, useState, Suspense } from "react";
import {
  Text,
  Grid,
  Tag,
  Tooltip,
  Toggle,
  Dot,
  Link,
  // Image,
  Loading,
} from "@geist-ui/react";

import LazyColorImg from "../lazyColorImg";

// const Image = React.lazy(() =>
//   import("@geist-ui/react").then((module) => ({ default: module.Image }))
// );

const PublicationCard = ({
  name,
  paperImg,
  title,
  tags,
  authors,
  status,
  paperLinks,
  descriptions,
}) => {
  const cardArea = useRef();
  const [isShort, setIsShort] = useState(true);

  // process authors
  let authorList = [];
  for (const index in authors) {
    const author = authors[index];
    const extension = index === authors.length - 1 ? "." : ", ";

    // check high light
    if (author[0] === "#") {
      authorList.push(
        <Text span b key={index}>
          {author.substr(1)}
          {extension}
        </Text>
      );
    } else {
      authorList.push(
        <Text span key={index}>
          {author}
          {extension}
        </Text>
      );
    }
  }

  return (
    <div className="right-pane-card-container" ref={cardArea}>
      <div className="right-pane-card-title-sec">
        {!!name ? (
          <Text className="right-pane-card-logo-text" span font="3rem" b>
            {name.toUpperCase()}
          </Text>
        ) : (
          <Suspense fallback={<Loading />}>
            <LazyColorImg src={process.env.PUBLIC_URL + paperImg} />
          </Suspense>
        )}
        {!!descriptions ? (
          <Tooltip
            text={isShort ? "Show details." : "Hide details."}
            placement="bottom"
          >
            <Toggle
              scale={1.5}
              type="secondary"
              onChange={() => {
                setIsShort(!isShort);
              }}
            />
          </Tooltip>
        ) : undefined}
      </div>
      <div className="right-pane-card-tex-area">
        <div className="right-pane-card-tex-paper-title">
          {/* Title */}
          <Text font="1.2rem" b>
            {title}
          </Text>
          {/* Authors */}
          <Text font="1.2rem">{authorList}</Text>
          {/* Status */}
          <Text font="1.2rem">{status}</Text>
          {/* Paper Link */}
          {paperLinks.length > 0 ? (
            <Grid.Container gap={3} justify="flex-start">
              {paperLinks.map(([text, link], i) => (
                <Grid key={i}>
                  {!!link ? (
                    <Link key={i} href={link} color target="_blank">
                      <Text font="1.2rem" span>
                        {text}
                      </Text>
                    </Link>
                  ) : (
                    <Text font="1.2rem" span>
                      {text}
                    </Text>
                  )}
                </Grid>
              ))}
            </Grid.Container>
          ) : undefined}
        </div>

        {/* Description */}
        <div className="right-pane-card-desc-area">
          <Grid.Container gap={2} justify="flex-start">
            {tags.map((tag, i) => (
              <Grid key={i}>
                <Tag>{tag}</Tag>
              </Grid>
            ))}
          </Grid.Container>
          {isShort ? undefined : (
            <div>
              {descriptions.map((text, i) => (
                <Text key={i}>
                  {" "}
                  <Dot /> {text}
                </Text>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PublicationCard;
