import React from "react";
import { Text } from "@geist-ui/react";

const CopyRightSymbol = () => {
  return (
    <div className="copyright-symbol">
      <Text>&copy; Weihao Zeng | 2021-2024</Text>
    </div>
  );
};

export default CopyRightSymbol;
