import React, { useContext } from "react";
import { Grid, Spacer, useMediaQuery } from "@geist-ui/react";

import LeftPane from "./LeftPane";
import RightPane from "./RightPane";
import ThemeContext from "./themeContext";
import ParticleBackground from "./ParticleBackground";

const MyPage = () => {
  const theme = useContext(ThemeContext);

  const isXs = useMediaQuery("xs");

  return (
    <div>
      <ParticleBackground isDark={theme.isDark} />

      {isXs ? (
        <div
          style={{
            overflowY: "scroll",
            overflowX: "hidden",
            height: "100vh",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <LeftPane />
          <Spacer h={4} />
          <RightPane />
          <Spacer h={4} />
        </div>
      ) : (
        <div>
          <Grid.Container gap={0.5} justify="center">
            <Grid xs={6} md={6}>
              <LeftPane className="left-pane" />
            </Grid>
            <Grid xs={18} md={18}>
              <div className="right-pane">
                <RightPane />
              </div>
            </Grid>
          </Grid.Container>
        </div>
      )}
    </div>
  );
};

export default MyPage;
