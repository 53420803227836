import React, { useEffect, useState } from "react";
import { Spacer, useMediaQuery } from "@geist-ui/react";

import {
  ProjectCard,
  SectionHeader,
  PublicationCard,
  CopyRightSymbol,
} from "./RightPaneComponents";
import DarkModeButton from "./darkModeButton";

const RightPane = () => {
  const [experienceList, setExperienceList] = useState(undefined);
  const isXs = useMediaQuery("xs");

  useEffect(() => {
    fetch(process.env.PUBLIC_URL + "/experiences.json")
      .then((res) => res.json())
      .then((experiences) => {
        const expList = [];

        let index = 0;

        for (const key of Object.keys(experiences)) {
          // expList.push([EXP_TYPE.TITLE, key]);
          expList.push(<SectionHeader key={index} name={key} />);

          index++;

          for (const exp of experiences[key]) {
            const expType = exp["type"];
            let card = undefined;

            switch (expType.toLowerCase()) {
              case "plain":
                card = <ProjectCard key={index} {...exp} />;
                break;
              case "publication":
                card = <PublicationCard key={index} {...exp} />;
                break;
              default:
                break;
            }

            expList.push(card);

            index++;
          }
        }

        setExperienceList(expList);
      });
  }, []);

  return (
    <div>
      {!!!isXs ? <DarkModeButton /> : undefined}

      <div className="right-pane-content">
        {experienceList}
        <Spacer />

        <CopyRightSymbol />
      </div>
    </div>
  );
};

export default RightPane;
