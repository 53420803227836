import React, { useEffect, useState } from "react";
import { GeistProvider, CssBaseline, Themes } from "@geist-ui/react";
import ReactGA from "react-ga4";

import { ThemeProvider } from "./components/themeContext";
import MyPage from "./components/MyPage";

import "./App.css";

const transparentLightTheme = Themes.createFromLight({
  type: "transparentLightTheme",
  palette: {
    background: "rgba(255, 255, 255, 0)",
  },
});

const transparentDarkTheme = Themes.createFromDark({
  type: "transparentDarkTheme",
  palette: {
    foreground: "#999999",
    background: "rgba(0, 0, 0, 0.8)",
  },
});

function App() {
  const [isDark, setIsDark] = useState(true);

  const theme = {
    isDark,
    setIsDark,
  };

  useEffect(() => {
    const prefersDark = window.matchMedia("(prefers-color-scheme: dark)")
      .matches;
    setIsDark(prefersDark);

    ReactGA.initialize("G-W0DP4YLTW0");
  }, []);

  const themeType = isDark ? "transparentDarkTheme" : "transparentLightTheme";

  return (
    <GeistProvider
      themes={[transparentLightTheme, transparentDarkTheme]}
      themeType={themeType}
    >
      <CssBaseline />
      <ThemeProvider value={theme}>
        <MyPage />
      </ThemeProvider>
    </GeistProvider>
  );
}

export default App;
