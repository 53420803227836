import { FileText, Github, Linkedin, Mail } from "@geist-ui/react-icons";
import { Button, Tooltip, useToasts, useClipboard } from "@geist-ui/react";
import React from "react";

const SocialIcons = () => {
  const [, setToast] = useToasts();
  const { copy } = useClipboard();
  const handleEmailClick = () => {
    copy("whz@cmu.edu");
    setToast({ text: "Email copied.", type: "secondary" });
  };

  const socialDic = [
    [FileText, "CV.pdf"],
    [Github, "https://github.com/MRzNone"],
    [Linkedin, "https://www.linkedin.com/in/whz"],
  ];

  return (
    <div className="left-pane-social-icons">
      {[
        socialDic.map(([component, link], i) => (
          <a href={link} target="_blank" rel="noreferrer" key={i + 1}>
            <Button shadow auto icon={React.createElement(component)} />
          </a>
        )),
      ].concat([
        <Tooltip text={"Copy email."} placement="top" key={0}>
          <Button onClick={handleEmailClick} shadow auto icon={<Mail />} />{" "}
        </Tooltip>,
      ])}
    </div>
  );
};

export default SocialIcons;
