import React from "react";
import { Divider, Text } from "@geist-ui/react";

const SectionHeader = ({ name }) => {
  name = name.toUpperCase();

  return (
    <div>
      <Text h2>
        {name}
        <div style={{ width: "20rem" }}>
          <Divider h={5} />
        </div>
      </Text>
    </div>
  );
};

export default SectionHeader;
