import React, { useRef, useState, Suspense } from "react";
import {
  Text,
  Grid,
  Tag,
  Tooltip,
  Toggle,
  Dot,
  Link,
  // Image,
  Loading,
} from "@geist-ui/react";

import LazyColorImg from "../lazyColorImg";

// const Image = React.lazy(() =>
//   import("@geist-ui/react").then((module) => ({ default: module.Image }))
// );

const ProjectCard = ({
  name,
  title,
  date,
  tags,
  descriptions,
  link,
  img = undefined,
  darkImg = undefined,
  scale = 1,
}) => {
  const cardArea = useRef();
  const [isShort, setIsShort] = useState(true);

  return (
    <div className="right-pane-card-container" ref={cardArea}>
      <div className="right-pane-card-title-sec">
        {/* <Text className="right-pane-card-logo-text" b span font="3rem">
          {name}
        </Text> */}

        {!!name ? (
          <Text className="right-pane-card-logo-text" span font="3rem" b>
            {name.toUpperCase()}
          </Text>
        ) : (
          <Suspense fallback={<Loading />}>
            {/* <Image src={process.env.PUBLIC_URL + img} /> */}
            <LazyColorImg scale={scale} lightImg={img} darkImg={darkImg} />
          </Suspense>
        )}

        {!!descriptions ? (
          <Tooltip
            text={isShort ? "Show details." : "Hide details."}
            placement="bottom"
          >
            <Toggle
              scale={1.5}
              type="secondary"
              onChange={() => {
                setIsShort(!isShort);
              }}
            />
          </Tooltip>
        ) : undefined}
      </div>
      <div className="right-pane-card-tex-area">
        {/* Title */}
        <div className="right-pane-card-title-text">
          <Text font="1.2rem" b>
            {title}{" "}
            {!!link ? (
              <Link href={link} color target="_blank">
                <Text>[Link]</Text>
              </Link>
            ) : undefined}
          </Text>
          <Text font="1.2rem">{date}</Text>
        </div>

        {/* Description */}

        <div className="right-pane-card-desc-area">
          <Grid.Container gap={2} justify="flex-start">
            {tags.map((tag, i) => (
              <Grid key={i}>
                <Tag>{tag}</Tag>
              </Grid>
            ))}
          </Grid.Container>
          {isShort ? undefined : (
            <div>
              {descriptions.map((text, i) => (
                <Text key={i}>
                  {" "}
                  <Dot /> {text}
                </Text>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
