import { useEffect, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim"; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.

const ParticleBackground = ({ isDark }) => {
  const [init, setInit] = useState(false);

  // this should be run only once per application lifetime
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    const targetTheme = isDark ? 'dark' : 'light';
    if (container.targetTheme !== targetTheme){
      container.loadTheme(targetTheme);
      container.targetTheme = targetTheme;
    }
  };

  if (init) {
    const particleJsonUrl = process.env.PUBLIC_URL + (isDark ? "/darkParticles.json" : "/lightParticles.json");

    return (
      <Particles
        id="tsparticles"
        url={particleJsonUrl}
        particlesLoaded={particlesLoaded}
      />
    );
  }

  return <></>;
};

export default ParticleBackground;